import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import "./layout.scss"

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag('js', new Date());

  gtag('config', 'UA-12410164-1');
};

const Layout = ({ children }) => {
  return (
    <div className="content">
      <Header />
      {children}
      <Footer />
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-12410164-1" />
      <script>{injectGA()}</script>
    </div>
  )
}

export default Layout
